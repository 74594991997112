import React from "react";
import Logo from "./assets/images/logo black transparent.png";
import "./assets/css/header.css";

const Header = () => {
  return (
    <>
      <header>
        <nav class="navbar navbar-expand-lg ">
          <div className="container">
            <div className="brand-logo">
              <a class="navbar-brand" href="#">
                <img
                  src={Logo}
                  alt="Sneak Peak Media"
                  className="header-logo"
                />
              </a>
            </div>
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div
              class="collapse navbar-collapse primary-menucollapse navbar-collapse primary-menu justify-content-end"
              id="navbarNav"
            >
              <ul class="navbar-nav">
                <li class="nav-item active">
                  <a class="nav-link menu-link" href="#">
                    Home <span class="sr-only">(current)</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link menu-link" href="#">
                    About
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link menu-link" href="#">
                    Services
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link menu-link" href="#">
                    Contact
                  </a>
                </li>
              </ul>
            </div>
            {/* <div className="menu-cta-btn d-md-block d-none">
            <button class="btn navbar-btn custom-btn">CTA Button</button>
          </div> */}
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;

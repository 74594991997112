import SmallBannerImg from "./assets/images/SmallBanner.gif";
import MainBannerImg from "./assets/images/Banner.png";
import WhoWeAreImg from "./assets/images/Who-We-Are.jpg";


export const homepage_banners = {
  title: "Digitize<br>Ideas",
  description: "We specialize in turning your visions into digital reality. With our innovative strategies and creative expertise, we empower businesses to thrive in the digital landscape. Let us transform your dreams into impactful digital solutions that drive growth and success.",
  smallBannerImg: SmallBannerImg,
  mainBannerImg: MainBannerImg,
  button1Text: "Let's Build Together",
  button1Link: "#",
  button2Text: "And Market Together",
  button2Link: "#",
  cta_button_text: "Let's Build Together",
  cta_button_link: "#",
};

export const homepage_whoweare = {
  heading: "Welcome to<br>Sneak Peak Media!",
  description:
    "At Sneak Peak Media, we specialize in empowering businesses to thrive in the digital world. Our comprehensive suite of services includes Social Media Marketing (SMM), Search Engine Optimization (SEO), Web Development, and Social Media Ads. With tailored strategies and a results-driven approach, we help businesses maximize their online presence and achieve their marketing goals.<br><br>Our experienced team brings a wealth of knowledge and expertise to every project, ensuring that our solutions are customized to meet your unique needs. From crafting engaging social media campaigns to optimizing your website for search engines, we're dedicated to delivering measurable results that drive growth and success for your business.<br><br>Ready to elevate your digital presence? Let Sneak Peak Media be your partner in digital success. Contact us today to learn more about how we can help your business thrive online.",
  buttonText: "Know More",
  buttonLink: "/about",
  image: WhoWeAreImg,
};

export const hompeage_moto = {
  motos: [
    {
      id: 1,
      heading: "Optimize",
    },
    {
      id: 2,
      heading: "Engage",
    },
    {
      id: 3,
      heading: "Convert",
    },
    {
      id: 4,
      heading: "Retain",
    },
  ],
};

export const homepage_services = {
  title: "Our Services",
  content:
    "At Sneak Peak Media, we specialize in empowering businesses to thrive in the digital world. Our comprehensive suite of services includes Social Media Marketing (SMM), Search Engine Optimization (SEO), Web Development, and Social Media Ads.<br><br>With tailored strategies and a results-driven approach, we help businesses maximize their online presence and achieve their marketing goals. Our experienced team brings a wealth of knowledge and expertise to every project, ensuring that our solutions are customized to meet your unique needs.<br><br>From crafting engaging social media campaigns to optimizing your website for search engines, we're dedicated to delivering measurable results that drive growth and success for your business.<br><br>Ready to elevate your digital presence? Let Sneak Peak Media be your partner in digital success. Contact us today to learn more about how we can help your business thrive online",
  buttonText: "Know More",
  buttonLink: "/services",
  services: [
    {
      id: 1,
      icon: "fa-solid fa-code mt-5 service-icons",
      heading: "Website Development",
      description:
        "Elevate your online presence with our innovative, creative, and functional web development expertise.",
    },
    {
      id: 2,
      icon: "fa-solid fa-maximize mt-5 service-icons",
      heading: "Search Engine Optimization",
      description:
        "Our SEO wizards work magic, boosting your website's visibility and driving targeted traffic to new heights.",
    },
    {
      id: 3,
      icon: "fa-solid fa-icons mt-5 service-icons",
      heading: "Social Media Marketing",
      description:
        "Spark engagement, cultivate community, and amplify your brand's digital footprint with our bespoke social media mastery.",
    },
    {
      id: 4,
      icon: "fas fa-ad mt-5 service-icons",
      heading: "Social Media Advertisement",
      description:
        "Targeted ads on platforms like Facebook, Instagram, and LinkedIn to expand brand reach and drive conversions effectively.",
    },
  ],
};

export const homepage_blogs = {
  heading: "Blogs",
  content:
    "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Inventore necessitatibus, molestias corrupti ",
  blogs: [
    {
      id: 1,
      title: "Hello World 1",
      description:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Inventore necessitatibus, molestias corrupti",
      blogUrl: "https://google.com/",
      image:
        "https://images.pexels.com/photos/1566909/pexels-photo-1566909.jpeg?cs=srgb&dl=pexels-alexander-grey-1566909.jpg&fm=jpg",
    },
    {
      id: 2,
      title: "Hello World 2",
      description:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Inventore necessitatibus, molestias corrupti",
      blogUrl: "https://youtube.com/",
      image:
        "https://media.istockphoto.com/id/1372528688/vector/colorful-horizontal-retro-background-in-style-hippie.jpg?s=612x612&w=0&k=20&c=hIogg58mVhNQL4o-uIUY4e1q_B8vuOsIrpKuFCD9CCw=",
    },
    {
      id: 3,
      title: "Hello World 3",
      description:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Inventore necessitatibus, molestias corrupti",
      blogUrl: "https://facebook.com/",
      image:
        "https://img.freepik.com/free-vector/psychedelic-optical-illusion-background-concept_23-2148556808.jpg?size=626&ext=jpg&ga=GA1.1.1224184972.1711756800&semt=sph",
    },
  ],
};

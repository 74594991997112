import React from 'react'
import { homepage_blogs } from '../data'

const Blogs = () => {

    const {blogs, heading, content} = homepage_blogs;

  return (
    <>
      <section className='homepage_blogs'>
        <div className="container">
            <div className="row mb-md-3 mb-4">
                <div className="blog-section-title-area col-md-9">
                    <h2 className='gradient section-title'>{heading}</h2>
                    <p className="blogs-content w-md-75 w-100">{content}</p>
                </div>
                <div className="view-all-btn d-md-block d-none text-end">
                    <button className="view-all btn custom-btn">View All</button>
                </div>
            </div>
            <div className="blogs-listing row">
                {blogs.map((blog) => (
                    <div className="blog col-xl-6 col-md-6 d-flex flex-column gap-3" key={blog.id}>
                        <img src={blog.image} alt="Blog Image" className='blog-image' />
                        <h2 className="blog-title mb-0">{blog.title}</h2>
                        <p className="blog-description">{blog.description}</p>
                </div>
                ))}
            </div>
            <div className="view-all-btn d-md-none d-block text-center">
              <button className="view-all btn custom-btn">View All</button>
            </div>
        </div>
      </section>
    </>
  )
}

export default Blogs
